<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <base-material-card color="success" inline title="Inventar" class="px-5 py-3">
          <v-form>
            <v-container class="py-0" fluid>
              <v-row dense class="text-no-wrap" align="center">
                <v-col cols="12">
                  <span class="d-none">{{ render }}</span>
                  <s-autocomplete
                    :value="record.fields['400'][0].a[0].val"
                    :label="'400a ' + $t('Titlu')"
                    :rules="inputValidate"
                    :dense="dense"
                    :hide-details="hided"
                    :clearable="false"
                    return-object
                    view-type="marc"
                    type="v-autocomplete"
                    item-text="fields.200.a.val"
                    item-value="fields.200.a.val"
                    collection="ebib_marc_mon"
                    @search="searchRelationMarc('400', 'a', 0, 'mon', null)"
                    @input="setLink('400', 'a', 0, 'mon', null, $event)"
                  >
                    <template v-slot:append>
                      <v-icon
                        class="pt-1"
                        tabindex="-1"
                        @click.stop.prevent="searchRelationMarc('400', 'a', 0, 'mon', null)"
                      >
                        mdi-magnify
                      </v-icon>
                    </template>
                  </s-autocomplete>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="record.ctg"
                    style="width:200px"
                    :hide-details="hided"
                    :dense="dense"
                    item-text="name"
                    item-value="value"
                    :label="$t('Categorie')"
                    :items="dictionary.categorii_inv"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <span class="d-none">{{ render }}</span>
                  <v-text-field
                    v-model="record.fields['100'][0].a[0].val"
                    v-mask="invMask()"
                    :rules="validateValue('100', 'a')"
                    style="width:200px"
                    :hide-details="hided"
                    :dense="dense"
                    autofocus
                    :label="'100a ' + $t('Inventar')"
                    @input="render++"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon tabindex="-1" v-on="on" @click.stop.prevent="genBarcode('ninv')">
                            mdi-refresh
                          </v-icon>
                        </template>
                        <span>{{ $t("Generare inventar") }}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <span class="d-none">{{ render }}</span>
                  <v-text-field
                    v-model="record.fields['200'][0].a[0].val"
                    :rules="validateDuplicate(true, dictionary['200a'], record.fields['200'][0].a[0].val)"
                    style="width:200px"
                    :hide-details="hided"
                    :dense="dense"
                    :label="'200a ' + $t('Barcode')"
                    @blur="checkDuplicate('inv', '200', 'a', record.fields['200'][0].a[0].val)"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon tabindex="-1" v-on="on" @click.stop.prevent="genBarcode('inv')">
                            mdi-refresh
                          </v-icon>
                        </template>
                        <span>{{ $t("Generare barcode") }}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="record.fields['210'][0].a[0].val"
                    :rules="validateValue('210', 'a')"
                    style="width:150px"
                    :hide-details="hided"
                    :dense="dense"
                    :label="'210a ' + $t('Cotă')"
                  />
                </v-col>
                <v-col cols="6" sm="4">
                  <v-text-field
                    v-model="record.fields['520'][0].a[0].val"
                    :rules="validateValue('520', 'a')"
                    style="width:150px"
                    :hide-details="hided"
                    :dense="dense"
                    :label="'520a ' + $t('Preț')"
                  />
                </v-col>
                <v-col cols="6" sm="4">
                  <v-select
                    v-model="record.fields['520'][0].c[0].val"
                    :rules="validateValue('520', 'c', record.fields['520'][0].c[0].val, dictionary.moneda, 'value')"
                    :error-messages="
                      errorMessages('520', 'c', record.fields['520'][0].c[0].val, dictionary.moneda, 'value')
                    "
                    style="width:150px"
                    :hide-details="hided"
                    :dense="dense"
                    item-text="name"
                    item-value="value"
                    :label="'520c ' + $t('Moneda')"
                    :items="dictionary.moneda"
                    @change="render++"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="record.fields['500'][0].a[0].val"
                    :rules="validateValue('500', 'a', record.fields['500'][0].a[0].val, dictionary.gestiune, 'value')"
                    :error-messages="
                      errorMessages('500', 'a', record.fields['500'][0].a[0].val, dictionary.gestiune, 'value')
                    "
                    :hide-details="hided"
                    :dense="dense"
                    item-text="name"
                    item-value="value"
                    :label="'500a ' + $t('Gestiune')"
                    :items="dictionary.gestiune"
                    @change="render++"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="record.fields['505'][0].a[0].val"
                    :rules="
                      validateValue('505', 'a', record.fields['505'][0].a[0].val, dictionary.regimImprumut, 'value')
                    "
                    :error-messages="
                      errorMessages('505', 'a', record.fields['505'][0].a[0].val, dictionary.regimImprumut, 'value')
                    "
                    :hide-details="hided"
                    :dense="dense"
                    item-text="name"
                    item-value="value"
                    :label="'505a ' + $t('Regim')"
                    :items="dictionary.regimImprumut"
                    @change="render++"
                  />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                    v-model="record.fields['501'][0].a[0].val"
                    :rules="validateValue('501', 'a', record.fields['501'][0].a[0].val, dictionary.localizare, 'value')"
                    :error-messages="
                      errorMessages('501', 'a', record.fields['501'][0].a[0].val, dictionary.localizare, 'value')
                    "
                    :hide-details="hided"
                    :dense="dense"
                    item-text="name"
                    item-value="value"
                    :label="'501a ' + $t('Localizare')"
                    :items="dictionary.localizare"
                    @change="render++"
                  />
                </v-col>
                <v-hover v-slot:default="{ hover }">
                  <v-col cols="12" sm="4">
                    <span class="d-none">{{ render }}</span>
                    <s-autocomplete
                      :value="record.fields['420'][0].a[0].val"
                      style="width:200px"
                      where='"ctg":"intrare",'
                      category="intrare"
                      :label="'420a ' + $t('RMF intrare')"
                      :dense="dense"
                      :hide-details="hided"
                      :clearable="false"
                      return-object
                      show-add
                      view-type="marc"
                      item-text="fields.200.a.val"
                      item-value="fields.200.a.val"
                      collection="ebib_marc_rmf"
                      type="v-autocomplete"
                      @search="searchRelationMarc('420', 'a', 0, 'rmf', 'intrare')"
                      @input="setLink('420', 'a', 0, 'rmf', 'intrare', $event)"
                      @focus="$set(dictionary, '420avshow', true)"
                      @blur="$set(dictionary, '420avshow', false)"
                    >
                      <template v-slot:append>
                        <v-icon
                          v-show="dictionary['420avshow'] || hover"
                          class="pt-1"
                          tabindex="-1"
                          @click.stop.prevent="searchRelationMarc('420', 'a', 0, 'rmf', 'intrare')"
                        >
                          mdi-magnify
                        </v-icon>
                      </template>
                    </s-autocomplete>
                    <!-- <v-subheader>{{ "420a " + $t("RMF intrare") }}</v-subheader>
                  <link-fields
                    v-model="record.fields['420']"
                    :render="render"
                    height="50px"
                    collection="ebib_marc_rmf"
                    @del="delSubItem('fields.420', $event)"
                    @search="searchRelationMarc('420', 'a', $event, 'rmf', 'intrare')"
                  /> -->
                  </v-col>
                </v-hover>
                <v-hover v-slot:default="{ hover }">
                  <v-col cols="12" sm="4">
                    <span class="d-none">{{ render }}</span>
                    <s-autocomplete
                      :value="record.fields['421'][0].a[0].val"
                      style="width:200px"
                      where='"ctg":"iesire",'
                      category="iesire"
                      :label="'421a ' + $t('RMF ieșire')"
                      :dense="dense"
                      :hide-details="hided"
                      :clearable="false"
                      return-object
                      show-add
                      view-type="marc"
                      item-text="fields.200.a.val"
                      item-value="fields.200.a.val"
                      collection="ebib_marc_rmf"
                      type="v-autocomplete"
                      @search="searchRelationMarc('421', 'a', 0, 'rmf', 'iesire')"
                      @input="setLink('421', 'a', 0, 'rmf', 'iesire', $event)"
                      @focus="$set(dictionary, '421avshow', true)"
                      @blur="$set(dictionary, '421avshow', false)"
                    >
                      <template v-slot:append>
                        <v-icon
                          v-show="dictionary['421avshow'] || hover"
                          class="pt-1"
                          tabindex="-1"
                          @click.stop.prevent="searchRelationMarc('421', 'a', 0, 'rmf', 'iesire')"
                        >
                          mdi-magnify
                        </v-icon>
                      </template>
                    </s-autocomplete>
                  </v-col>
                </v-hover>
                <!-- <v-col cols="12" sm="6">
                  <v-subheader>{{ "421a " + $t("RMF ieșire") }}</v-subheader>
                  <link-fields
                    v-model="record.fields['421']"
                    :render="render"
                    height="50px"
                    collection="ebib_marc_rmf"
                    @del="delSubItem('fields.421', $event)"
                    @search="searchRelationMarc('421', 'a', $event, 'rmf', 'iesire')"
                  />
                </v-col> -->
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="record.fields['700'][0].a[0].val"
                    :rules="validateValue('700', 'a')"
                    style="width:150px"
                    :hide-details="hided"
                    :dense="dense"
                    readonly
                    :label="'700a ' + $t('Disponibil')"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="record.fields['355'][0].a[0].val"
                    :rules="validateValue('355', 'a')"
                    :hide-details="hided"
                    :dense="dense"
                    :label="'355a ' + $t('Dețineri')"
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model="record.fields['300'][0].a[0].val"
                    :rules="validateValue('300', 'a')"
                    :hide-details="hided"
                    :dense="dense"
                    rows="3"
                    :label="'300a ' + $t('Note')"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <search-link ref="searchLink" select-event @select="setLinkValue" />
    <create-edit ref="createEdit" />
  </v-container>
</template>

<script>
import SearchLink from "../../../components/containers/SearchLink"
import CreateEdit from "../../../views/settings/AddDictionaryEdit"
// import LinkFields from "../../../components/containers/LinkFields"
// import SDateTime from "../../../components/inputs/DateTime"
import SAutocomplete from "../../../components/inputs/Autocomplete"

import { EventBus } from "../../../EventBus"
import axios from "../../../plugins/axios"
//import axio from "axios"
export default {
  name: "DashboardDashboard",
  components: {
    SearchLink,
    CreateEdit,
    // LinkFields,
    // SDateTime,
    SAutocomplete
  },
  data() {
    return {
      dense: true,
      hided: false,
      fileName: "",
      file: {},
      urlImg: null,
      searchId: "",
      dictionary: {},
      linkArray: null,
      linkField: null,
      linkSubfield: null,
      linkIndex: null,
      selectId: null,
      render: 1,
      record: {
        company: "",
        id: "",
        ctg: "",
        fields: {
          100: [{ ord: "", a: [{ ord: "", val: "" }], p: [{ ord: "", val: "" }], s: [{ ord: "", val: "" }] }],
          200: [{ ord: "", a: [{ ord: "", val: "" }] }],
          210: [{ ord: "", a: [{ ord: "", val: "" }] }],
          220: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }]
            }
          ],
          230: [{ ord: "", a: [{ ord: "", val: "" }] }],
          299: [{ ord: "", a: [{ ord: "", val: "" }] }],
          300: [{ ord: "", a: [{ ord: "", val: "" }] }],
          330: [{ ord: "", a: [{ ord: "", val: "" }] }],
          350: [{ ord: "", a: [{ ord: "", val: "" }] }],
          355: [{ ord: "", a: [{ ord: "", val: "" }] }],
          356: [{ ord: "", a: [{ ord: "", val: "" }] }],
          400: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          420: [
            {
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              p: [{ ord: "", src_id: "", val: "" }],
              s: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          421: [
            {
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              p: [{ ord: "", src_id: "", val: "" }],
              s: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          500: [{ ord: "", a: [{ ord: "", val: "" }] }],
          501: [{ ord: "", a: [{ ord: "", val: "" }] }],
          505: [{ ord: "", a: [{ ord: "", val: "" }] }],
          520: [{ ord: "", a: [{ ord: "", val: "" }], c: [{ ord: "", val: "" }] }],
          521: [{ ord: "", a: [{ ord: "", val: "" }], c: [{ ord: "", val: "" }] }],
          700: [{ ord: "", a: [{ ord: "", val: "" }] }],
          801: [
            {
              2: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }]
            }
          ],
          856: [
            {
              1: [{ ord: "", val: "" }],
              ord: "",
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              q: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          900: [{ ord: "", a: [{ ord: "", val: "" }] }],
          901: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }]
            }
          ],
          916: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          917: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          989: [{ ord: "", a: [{ ord: "", val: "" }] }],
          "001": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "002": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "003": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "005": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "006": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "007": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "008": [{ ord: "", a: [{ ord: "", val: "" }] }]
        },
        unknown_fields: [{}]
      },
      recordView: {
        company: "",
        id: "",
        ctg: "",
        fields: {
          100: [{ ord: "", a: [{ ord: "", val: "" }], p: [{ ord: "", val: "" }], s: [{ ord: "", val: "" }] }],
          200: [{ ord: "", a: [{ ord: "", val: "" }] }],
          210: [{ ord: "", a: [{ ord: "", val: "" }] }],
          220: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }],
              e: [{ ord: "", val: "" }]
            }
          ],
          230: [{ ord: "", a: [{ ord: "", val: "" }] }],
          299: [{ ord: "", a: [{ ord: "", val: "" }] }],
          300: [{ ord: "", a: [{ ord: "", val: "" }] }],
          330: [{ ord: "", a: [{ ord: "", val: "" }] }],
          350: [{ ord: "", a: [{ ord: "", val: "" }] }],
          355: [{ ord: "", a: [{ ord: "", val: "" }] }],
          356: [{ ord: "", a: [{ ord: "", val: "" }] }],
          400: [{ ord: "", link: "", a: [{ ord: "", src_id: "", val: "" }] }],
          420: [
            {
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              p: [{ ord: "", src_id: "", val: "" }],
              s: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          421: [
            {
              ord: "",
              link: "",
              a: [{ ord: "", src_id: "", val: "" }],
              p: [{ ord: "", src_id: "", val: "" }],
              s: [{ ord: "", src_id: "", val: "" }]
            }
          ],
          500: [{ ord: "", a: [{ ord: "", val: "" }] }],
          501: [{ ord: "", a: [{ ord: "", val: "" }] }],
          505: [{ ord: "", a: [{ ord: "", val: "" }] }],
          520: [{ ord: "", a: [{ ord: "", val: "" }], c: [{ ord: "", val: "" }] }],
          521: [{ ord: "", a: [{ ord: "", val: "" }], c: [{ ord: "", val: "" }] }],
          700: [{ ord: "", a: [{ ord: "", val: "" }] }],
          801: [
            {
              2: [{ ord: "", val: "" }],
              ord: "",
              ind2: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              g: [{ ord: "", val: "" }],
              h: [{ ord: "", val: "" }]
            }
          ],
          856: [
            {
              1: [{ ord: "", val: "" }],
              ord: "",
              d: [{ ord: "", val: "" }],
              f: [{ ord: "", val: "" }],
              q: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          900: [{ ord: "", a: [{ ord: "", val: "" }] }],
          901: [
            {
              ord: "",
              a: [{ ord: "", val: "" }],
              b: [{ ord: "", val: "" }],
              c: [{ ord: "", val: "" }],
              d: [{ ord: "", val: "" }]
            }
          ],
          916: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          917: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          989: [{ ord: "", a: [{ ord: "", val: "" }] }],
          "001": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "002": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "003": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "005": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "006": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "007": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "008": [{ ord: "", a: [{ ord: "", val: "" }] }]
        },
        unknown_fields: [{}]
      },
      schema: {
        dictionary: [
          "all.moneda",
          "all.regimImprumut",
          "all.gestiune",
          "all.localizare",
          "all.TipExInv",
          "all.MotivCas",
          "all.categorii_inv"
        ]
      },
      rows: []
    }
  },

  computed: {
    inputValidate() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    }
  },
  created() {
    if (this.schema.dictionary) {
      this.schema.dictionary.map(itm => this.getDictionaryValues(itm))
    }
    const country = []
    this.rows.map(itm => {
      country.push({ name: itm.value, value: itm.value })
    })
    EventBus.$on("dictionary-reload", dict => {
      this.$log("dictionary-reload ", dict)
      if (this.selectId == dict.id) {
        this.getDictionaryValues("all." + dict.dictionary)
        if (dict.select) {
          this.setLinkValue(dict.select)
        }
      }
    })
    // EventBus.$on("table-form-select", value => {
    //   this.$log("selected ", value)
    // })
    //this.$log(JSON.stringify(country))
    // axios.post("anaf_check_tva", [{ cui: 18158683, data: "2020-04-30" }]).then(response => {
    //   this.$log("response ", response)
    // })
  },
  methods: {
    invMask() {
      const prefix = [/[a-zA-Z]/, /[a-zA-Z]/]
      const nr = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
      const sufix = [/[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/]
      if (this.record.fields["100"][0].a[0].val.length == 0) {
        return [/[0-9a-zA-Z]/]
      } else if (this.record.fields["100"][0].a[0].val.length > 0) {
        if (Number.isInteger(Number(this.record.fields["100"][0].a[0].val.substr(0, 1)))) {
          if (this.record.fields["100"][0].a[0].val.length > 7) {
            return [...nr, "/", ...sufix]
          } else {
            return [...nr]
          }
        } else {
          if (this.record.fields["100"][0].a[0].val.length > 10) {
            return [...prefix, "/", ...nr, "/", ...sufix]
          } else {
            return [...prefix, "/", ...nr]
          }
        }
      }
    },
    validateValue(fld, sub) {
      const rule = []
      //rule.push(v => !!v || this.$t("required"))
      return rule
    },
    checkDuplicate(pre, fld, sub, val) {
      if (val) {
        axios.post("marc/is_duplicated_fs_value/" + pre + "/" + fld + "/" + sub + "/" + val).then(response => {
          this.$set(this.dictionary, fld + sub, response.data == 1)
        })
      }
    },
    validateDuplicate(mnd, dup, v) {
      const rule = []
      if (mnd) {
        rule.push(v => !!v || this.$t("required"))
      }
      if (dup) {
        rule.push(v => this.$t("Valoarea trebuie să fie unică!!!"))
      }
      return rule
    },
    genBarcode(prefix) {
      if (prefix == "cln") {
        axios.get("generate_barcode_client").then(response => {
          this.$log("genBarcode ", response)
          if (response.data.code == 1) {
            this.$set(this.record.fields["500"][0].a[0], "val", response.data.data.barcode)
            this.render++
          }
        })
      } else if (prefix == "inv") {
        axios.get("generate_barcode_inventory").then(response => {
          this.$log("genBarcode ", response)
          if (response.data.code == 1) {
            this.$set(this.record.fields["200"][0].a[0], "val", response.data.data.barcode)
            this.render++
          }
        })
      } else if (prefix == "ninv") {
        axios.get("generate_number_inventory").then(response => {
          this.$log("genBarcode ninv ", response)
          if (response.data.code == 1) {
            this.$set(this.record.fields["100"][0].a[0], "val", response.data.data.nr_inv)
            this.render++
          }
        })
      }
    },
    errorMessages(fld, sub, v, items, field) {
      if (this.prefix == "cln") {
        if (fld == "505" && sub == "b" && v) {
          const d = this.$store.getters.serverDate
          const arr = v.split(".")
          const d1 = new Date(arr[2] + "-" + arr[1] + "-" + arr[0])
          if ((d - d1) / (1000 * 60 * 60 * 24) > 365 * 5) {
            return "Înscriere expirată"
          }
          return ""
        }
        if (fld == "505" && sub == "e" && v) {
          const d = this.$store.getters.serverDate
          const arr = v.split(".")
          const d1 = new Date(arr[2] + "-" + arr[1] + "-" + arr[0])
          if ((d - d1) / (1000 * 60 * 60 * 24) > 365) {
            return "Vizare expirată"
          }
          return ""
        }
      }
      //this.$log("errorMessages1 ", v)
      //this.$log("errorMessages2 ", items)
      if (v && items) {
        if (field) {
          if (!items.find(itm => itm[field] == v)) {
            return 'Valoarea "' + v + '" nu se află in listă'
          }
        } else {
          if (items.indexOf(v) < 0) {
            return 'Valoarea "' + v + '" nu se află in listă'
          }
        }
      }
      return ""
    },
    getInv() {
      axios.get('ebib_loan_active?aggregate={"$where":{},"$sort":{}}&max_results=10').then(response => {
        this.$log("resinvtm ", response)
      })
    },
    createURL(img, fil) {
      this.$set(this.dictionary, img, URL.createObjectURL(fil.target.files[0]))
    },
    fileChanged(e) {
      this.file = e.target.files[0]
      this.urlImg = URL.createObjectURL(this.file)
      this.fileName = this.file.name
    },
    clearFile() {
      this.fileName = ""
      this.file = null
    },
    createAndEdit(e, arr, field, ind) {
      //searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.selectId = Date.now()
      this.$log("createAndEdit ", e)
      this.$refs.createEdit.showData(e, this.selectId)
    },
    checkTVA(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
          }
        })
      }
    },
    loadCUIData(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
            this.record.name = response.data.found[0].denumire
            const adr = response.data.found[0].adresa.split(",")
            if (adr.length > 2) {
              this.record.address.address = response.data.found[0].adresa.replace(adr[0] + "," + adr[1] + ",", "")
            }
            this.record.address.locality = adr[1]
            this.record.address.country = "România"
            if (adr[0].substr(0, 3) == "JUD") {
              const jud = adr[0].substr(5).toLowerCase()
              this.record.address.county = adr[0].substr(5)
              this.dictionary.county.map(itm => {
                if (itm.value.toLowerCase() == jud) {
                  this.record.address.county = itm.value
                }
              })
            } else {
              this.record.address.county = "Bucureşti"
            }
          }
        })
      }
    },
    getdictionary(dic) {
      return this.$store.getters.dictionary(dic)
    },
    getDictionaryValues(dic) {
      //this.$log("getDictionaryValues(itm) ", dic)
      const dictionary = dic.split(".")
      if (dictionary[0] == "all") {
        //axios.get('app_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
        this.$set(this.dictionary, dictionary[1], this.getdictionary("all_" + dictionary[1]))
        // this.dictionary[dictionary[1]] = response.data._items[0].content
        //})
      } else if (dictionary[0] == "system") {
        axios.get('app_system_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
          this.$set(this.dictionary, dictionary[1], response.data._items[0].content)
          // this.dictionary[dictionary[1]] = response.data._items[0].content
        })
      }
    },
    addSubItem(e, ind) {
      if (ind == null) {
        this.resolvePath(e, this.record).push(JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0])))
      } else {
        this.resolvePath(e, this.record).splice(
          ind,
          0,
          JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0]))
        )
      }
    },
    delSubItem(e, ind) {
      this.resolvePath(e, this.record).splice(ind, 1)
      if (this.resolvePath(e, this.record).length == 0) {
        this.addSubItem(e)
      }
    },
    searchRelationMarc(field, subfield, ind, prefix, ctg) {
      this.linkField = field
      this.linkSubfield = subfield
      this.linkIndex = ind
      //this.$log("srcrel ", this.schemaContent[fields.field + subfield])
      this.searchId = Date.now()
      let where = ""
      let vTable = ""
      if (ctg) {
        where = '"ctg":' + '"' + ctg + '"'
        const ctgview = this.$store.getters.appViewTableCollectionCategory("marc_" + prefix, ctg)
        if (ctgview.length > 0) {
          vTable = ctgview[0]._id
        }
      }
      this.$refs.searchLink.open("ebib_marc_" + prefix, null, this.searchId, {
        where: where,
        viewTable: vTable,
        ctg: ctg
      })
      // EventBus.$emit("search-link", {
      //   resource: "ebib_marc_" + prefix,
      //   field: null,
      //   id: this.searchId,
      //   config: { where: where, viewTable: vTable, ctg: ctg }
      // })
      //this.$refs.searchLink.open(res, fld, this.searchId)
    },
    searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.searchId = Date.now()
      this.$refs.searchLink.open(res, fld, this.searchId)
    },
    setLink(field, subfield, ind, prefix, ctg, e) {
      this.$log("eeee ", e)
    },
    setLinkValue(e) {
      if (this.linkIndex != null) {
        if (this.linkField != null) {
          //this.linkArray[this.linkIndex][this.linkField] = e
          this.$set(this.linkArray[this.linkIndex], this.linkField, e)
        } else {
          if (typeof e === "object") {
            e.iiid = this.idGlobal++
          }
          if (this.linkIndex == -1) {
            this.linkArray.push(e)
          } else {
            //this.linkArray[this.linkIndex] = e
            this.$set(this.linkArray, this.linkIndex, e)
          }
        }
      } else {
        //this.linkArray[this.linkField] = e
        this.$set(this.linkArray, this.linkField, e)
        this.render = Date.now()
      }
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
